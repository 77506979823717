// Other utility classes

@each $color, $value in $grays {
    .text-gray-#{$color} {
        color: var(--#{$prefix}gray-#{$color}) !important;
    }

    .bg-gray-#{$color} {
        background-color: var(--#{$prefix}gray-#{$color}) !important;
    }

    .border-gray-#{$color} {
        border-color: var(--#{$prefix}gray-#{$color}) !important;
    }
}

.header-badge::before {
    content: "";
    display: inline-block;
    width: 0.375rem;
    height: 1rem;
    margin-right: 0.313rem;
    background: $orange 0% 0% no-repeat padding-box;
    border-radius: 0.125rem;
}

h2.header-badge::before {
    vertical-align: top;
}

.header-badge-green-200::before {
    background: $green-200 0% 0% no-repeat padding-box;
}

.header-badge-green-500::before {
    background: $green-500 0% 0% no-repeat padding-box;
}

.header-badge-green-700::before {
    background: $green-700 0% 0% no-repeat padding-box;
}

.header-badge-blue-200::before {
    background: $blue-200 0% 0% no-repeat padding-box;
}

.header-badge-blue-500::before {
    background: $blue-500 0% 0% no-repeat padding-box;
}

.header-badge-yellow-500::before {
    background: $yellow-500 0% 0% no-repeat padding-box;
}

.text-ad-blue {
    color: #286bf0;
}

.min-width-w100 {
    min-width: 100%;
}

.bg-orange-100 {
    background-color: $orange-100;
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}